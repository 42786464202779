@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  input:focus ~ label,
  input:not(:placeholder-shown) ~ label {
    @apply -translate-y-2 text-xsmall-regular;
  }

  input:focus ~ label {
    @apply left-0;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid #212121;
    -webkit-text-fill-color: #212121;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
}

@layer components {
  .content-container {
    @apply max-w-[1440px] w-full mx-auto px-8;
  }

  .contrast-btn {
    @apply px-4 py-2 border border-black rounded-full hover:bg-black hover:text-white transition-colors duration-200 ease-in;
  }

  .text-xsmall-regular {
    @apply text-[10px] leading-4 font-normal;
  }

  .text-small-regular {
    @apply text-xs leading-5 font-normal;
  }

  .text-small-semi {
    @apply text-xs leading-5 font-semibold;
  }

  .text-base-regular {
    @apply text-sm leading-6 font-normal;
  }

  .text-base-semi {
    @apply text-sm leading-6 font-semibold;
  }

  .text-large-regular {
    @apply text-base leading-6 font-normal;
  }

  .text-large-semi {
    @apply text-base leading-6 font-semibold;
  }

  .text-xl-regular {
    @apply text-2xl leading-[36px] font-normal;
  }

  .text-xl-semi {
    @apply text-2xl leading-[36px] font-semibold;
  }

  .text-2xl-regular {
    @apply text-[30px] leading-[48px] font-normal;
  }

  .text-2xl-semi {
    @apply text-[30px] leading-[48px] font-semibold;
  }

  .text-3xl-regular {
    @apply text-[36px] leading-[48px] font-normal;
  }

  .text-3xl-semi {
    @apply text-[36px] leading-[48px] font-semibold;
  }
}

table {
  box-sizing: border-box;
  border-collapse: collapse;
}

th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
}

td,
th {
  padding: 8px;
}

tr:nth-of-type(even) {
  background-color: revert;
}

@media (max-width: 768px) {
  table {
    width: 100%;
  }
  table tr {
    margin-bottom: 8px;
    display: block;
  }
  table thead {
    display: none;
  }

  table td {
    display: flex;
    padding-left: 0.75em !important;
  }
  table td::before {
    display: flex;
    align-items: center;
    content: attr(data-label);
    font-weight: bold;
    width: 200px;
    min-width: 200px;
    margin: -8px 8px -8px -8px;
    padding: 8px;
  }
}


table.mobile {
  width: 100%;
}
table.mobile tr {
  margin-bottom: 8px;
  display: block;
}
table.mobile thead {
  display: none;
}

table.mobile td {
  display: flex;
  padding-left: 0.75em !important;
}
table.mobile td::before {
  display: flex;
  align-items: center;
  content: attr(data-label);
  font-weight: bold;
  width: 200px;
  min-width: 200px;
  margin: -8px 8px -8px -8px;
  padding: 8px;
}